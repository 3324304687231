<template>
<div class="page-transistion">
  <div class="page-content">
      <div class="blura">
        <div class="desktop-subtitle">
          <p class="subtitle">
            <span class="bold-subtitle">Hi, I'm Thomas Bestauros!</span>
            <span class="small-subtitle"> I’m a full-stack developer & designer based in France. I build apps for a variety of platforms; including web & mobile.</span>
          </p>
        </div>
        <div class="about-content">
          <div class="about-image">
            <img src="@/assets/img/kjt.jpg" alt="">
          </div>
          <div class="about-text">
            <p>
              My education and work experiences have taught me the need for
              constant learning and evolving in the career of a developer/designer. I
              believe that there is no formula to a good user experience and
              every project provides an opportunity for discovery.
            </p>
            <p>
              With a passion for user-centered design, a problem solving
              approach, and a strong growth mindset, my intent is to test the
              boundaries of user interactions and create innovative product
              experiences.
            </p>
            <a href="" target="_blank">
              view my resume
            </a>
          </div>
        </div>
               
        </div>

      </div>
             <Footer />
</div>
</template>

<style scoped>

.page-content {
    background: ghostwhite;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    padding: 8px 12.5% 100px 12.5%;
}
.subtitle {
    font-size: 36px;
    line-height: 1.5em;
    box-sizing: border-box;
    margin: 100px 0px 100px 0px;

}
.bold-subtitle {
    font-weight: 700;
    display: inline;
}
.small-subtitle {
    font-size: 20px;
    display: block;
    line-height: 1.5em;
    margin-top: 24px;
    font-weight: 400;
    color: #71798F;
        font-size: 24px;

}

.about-content {

    animation-fill-mode: forwards;
}

.about-image {
    width: 100%;
    height: auto;
    margin-bottom: 36px;
}
.about-image img {
    width: 100%;
}

.about-text {
    font-size: 16px;
    width: 100%;
    line-height: 1.5em;
    display: flex;
    flex-direction: column;
}
.about-text p {
    margin-bottom: 36px;
}

.about-text a {
    width: 140px;
    height: 2em;
    font-size: 14px;
    border-radius: 20px;
    background-color: white;
    border: 1px solid rgba(113, 121, 143, 0.4);
    color: #71798f;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media (min-width: 992px) and (max-width: 2919px){
.page-content {
    padding: 80px 12.5% 100px 12.5%;
}
.desktop-subtitle {
    width: 100%;
}

.subtitle {
    font-size: 36px;
    line-height: 1.5em;
    box-sizing: border-box;
    margin: 100px 0px 100px 0px;
}
.small-subtitle {
    font-size: 24px;
    line-height: 1.5em;
    width: 50%;
}

.about-content {
    box-sizing: border-box;
    padding: 0px;
    width: auto;
    display: grid;
    grid-template-columns: 44.444% 44.444%;
    grid-gap: 0px 11.112%;
}

.about-image {
    margin-bottom: 60px;
}
.about-text p {
    line-height: 30px;
    margin: 0px 0px 2em 0px;
        font-size: 18px;
}
}
</style>
<script>
  import Footer from '@/components/Footer.vue';
import { gsap} from "gsap";
  export default {
  name: 'App',
  components: {
    Footer
  },
  mounted: function(){
    this.startAnimations()
    },
    methods: {
    startAnimations: function(){
     this.blura = gsap
     .to(".blura",1, {opacity: 1,y:0, filter:'blur(0.1px)'})
  }
}
}

</script>
