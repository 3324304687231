<template>
<div class="page-transition">
<div class="contact page">
  <div class="intro">
  <div class="intro-content">
    <div class="an_intro_1">
    <h1>Me contacter</h1>
    </div>
    <div class="an_intro_2">
    <h2>Every project starts with a talk. Just write me an</h2>
    <h2>email and let's bring your ideas into the life.</h2>
    </div>
    </div>
    <div id="button">
    <span class="arrow" style="opacity: 0.8;"><a href="#">✉ CONTACT ME</a></span>
    </div>
  </div>
</div>
      <Footer />
</div>
</template>

<style scoped>
.intro {
    background: #A4ADB7;
}


</style>
<script>

  import { gsap } from 'gsap';
    import Footer from '@/components/Footer.vue';
  export default {
      name: 'App',
  components: {
    Footer
  },
  mounted: function(){
    this.startAnimations()
    },
    methods: {
    startAnimations: function(){;
     gsap.from(".an_intro_1", {opacity: 0, y: 70})
     gsap.from(".an_intro_2", {delay: 0.5,opacity: 0, y: 70})
     gsap.from("#button", {delay: 1,opacity: 0, y: -70});
  }
}
}
</script>
