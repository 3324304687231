<template>
<div class="page-transition">
  <div class="page-content">
    <div class="blura">
        <div class="desktop-subtitle">
          <p class="subtitle">
            Take a look at what I've been working on!
          </p>
        </div>
        <div class="project-grid">
          <div class="project">
            <router-link class="project-image-container" to="/jbfilms" >
              <img src="@/assets/img/1.jpg" alt="Impressions" id="thumbnail-1" class="project-image">
            </router-link>
            <h1 class="project-title">JBFilms portfolio </h1>
            <h2 class="project-title">Service Design, Wordpress developement</h2>
          </div>
          <div class="project">
            <a href="projects/portal.html" class="project-image-container">
              <img src="@/assets/img/2.jpg" alt="Patent portal" id="thumbnail-2" class="project-image">
            </a>
            <h1 class="project-title">Patent Portal</h1>
            <h2 class="project-title">Company Portal, UX Design</h2>
          </div>
          <div class="project">
            <a href="projects/zoom.html" class="project-image-container">
              <img src="@/assets/img/3.jpg" alt="Zoom" id="thumbnail-3" class="project-image">
            </a>
            <h1 class="project-title">Zoom Case Study</h1>
            <h2 class="project-title">Feature Design</h2>
          </div>
          <div class="project">
            <a href="projects/fca.html" class="project-image-container">
              <img src="@/assets/img/impressions.jpg" alt="FCA AI Concept" id="thumbnail-4" class="project-image"></a>
            <h1 class="project-title">Fiat Chrysler AI Concept</h1>
            <h2 class="project-title">UX Internship</h2>
          </div>

          <!-- <div class="project">
            <a href="projects/bridge.html" class="project-image-container">
              <img
                src="media/bridge.jpg"
                alt="The Bridge"
                id="thumbnail-9"
                class="project-image"
              />
            </a>
            <h1 class="project-title">The Bridge</h1>
            <h2 class="project-title">Mobile App Redesign</h2>
          </div> -->
        </div>
       </div> 
      </div>
      <Footer />
      </div>
</template>

<style scoped>
.intro {
  background: #516074;
}
.page-content {
  background: ghostwhite;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
   padding: 8px 12.5% 100px 12.5%;
}

  .subtitle {
    font-size: 36px;
    line-height: 1.5em;
    box-sizing: border-box;
    margin: 100px 0px 100px 0px;
  }
@media (min-width: 992px) and (max-width: 2919px){
  .page-content {
    padding: 80px 12.5% 100px 12.5%;
  }
  .landing-page-content {
    padding-bottom: 170px;
  }
  .landing-about-section {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    margin-top: 40px;
  }
  .landing-work-section {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .desktop-subtitle {
    width: 100%;
  }
  .subtitle {
    font-size: 36px;
    line-height: 1.5em;
    box-sizing: border-box;
    margin: 100px 0px 100px 0px;
  }
  .landing-subtitle {
    margin-bottom: 0px;
  }
  .subtitle a {
    width: 140px;
    height: 2em;
    font-size: 14px;
    font-weight: 400;
    border-radius: 20px;
    background-color: white;
    border: 1px solid rgba(113, 121, 143, 0.4);
    color: #71798f;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
  }
  .subtitle a:hover {
    background-color: #71798f;
    color: white;
    border: none;
  }
  .small-subtitle {
    font-size: 24px;
    line-height: 1.5em;
    width: 50%;
  }
  .landing-work-section .small-subtitle {
    width: 100%;
    margin-top: 8px;
  }
  .landing-about-section .subtitle-image {
      display: flex;
  }
  .subtitle-image {
    width: 100%;
    box-sizing: border-box;
    height: auto;
    display: flex;
    margin-bottom: auto;
    padding-right: 11.111%;
  }

  .image-top {
    width: 88.889%;
    height: auto;
    position: absolute;
    top: 0;
    right: 0;
    box-sizing: border-box;
  }

  #image-top-1 {
    margin: 0px 0px 0px 11.111%;
  }

  #image-top-2 {
    width: calc(88.889% - 32px);
    margin: 0px calc(11.111% + 16px) 0px 16px;
  }

  .image-background {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .image-block {
    background: rgba(9, 58, 206, 0.1);
    position: absolute;
    width: 100%;
    height: auto;
    bottom: 0;
    top: 0;
    right: 0;
    z-index: -1;
    box-sizing: border-box;
    margin: 0px;
  }

  #image-block-2 {
    background: rgba(166, 216, 212, 0.3);
  }

  .image-bottom {
    width: 100%;
    height: auto;
    vertical-align: top;
    box-sizing: border-box;
    margin: 0px;
  }

  #image-bottom-2 {
    width: calc(100% - 32px);
    margin: 0px 16px;
  }
}

.work {
      padding-top: 90px;
          background: #fff;
}
.subtitle {
  animation: fadeIn 0.75s ease-in-out;
}


@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.project {
  display: flex;
  flex-direction: column;
  margin-bottom: 36px;
}

.project:last-of-type {
  margin-bottom: 0px;
}

.project-image-container {
  width: 100%;
  height: auto;
  overflow: hidden;
  line-height: 0;
  position: relative;
}

.project-image {
  width: 100%;
  height: 100%;
  transition: all 0.5s cubic-bezier(0.45, 0.05, 0.55, 0.95);
}

.project-image-container:hover .project-image {
  transform: scale(1.1);
}

.project h1 {
  color: #00071c;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.5em;
  margin-top: 0.5em;
  margin-bottom: 0px;
}

.project h2 {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  font-weight: 400;
  line-height: 1.5em;
  color: #71798f;
  margin-top: 0px;
}

@media (min-width: 576px) and (max-width: 991px) {
  .project {
    margin-bottom: 60px;
  }
  .project h1 {
    font-size: 20px;
  }
  .project h2 {
    font-size: 12px;
  }
}

@media (min-width: 992px) and (max-width: 1919px) {
  .project-grid {
    box-sizing: border-box;
    padding: 0px;
    display: grid;
    grid-template-columns: 44.444% 44.444%;
    grid-column-gap: 11.112%;
  }
  .project {
    margin-bottom: 80px;
  }
  .project-image {
    width: 100%;
  }
  .project:last-of-type {
    display: flex;
  }
  .project:nth-of-type(2) {
    margin: 80px 0px 0px 0px;
  }
  .project:nth-of-type(4) {
    margin: 80px 0px 0px 0px;
  }
  .project:nth-of-type(6) {
    margin: 80px 0px 0px 0px;
  }
  .project:last-of-type {
    margin: 80px 0px 0px 0px;
  }
  .project h1 {
    width: 100%;
    font-size: 22px;
  }
  .project h2 {
    font-size: 14px;
    width: 100%;
  }
  /*.project-title {
    transform: translateY(-10px);
    opacity: 0;
    transition: all 0.5s ease-in-out;
  }
  .project-image-container:hover ~ .project-title {
    transform: translateY(0px);
    opacity: 1;
  }*/
  .desktop-subtitle {
    width: 50%;
  }
}

@media (min-width: 1920px) {
  .project-grid {
    box-sizing: border-box;
    padding: 0px;
    display: grid;
    grid-template-columns: 44.444% 44.444%;
    grid-column-gap: 11.112%;
  }
  .project {
    margin-bottom: 80px;
  }
  .project-image {
    width: 100%;
  }
  .project:last-of-type {
    display: flex;
  }
  .project:nth-of-type(2) {
    margin: 80px 0px 0px 0px;
  }
  .project:nth-of-type(4) {
    margin: 80px 0px 0px 0px;
  }
  .project:nth-of-type(6) {
    margin: 80px 0px 0px 0px;
  }
  .project:last-of-type {
    margin: 80px 0px 0px 0px;
  }
  .project h1 {
    width: 100%;
    font-size: 26px;
  }
  .project h2 {
    font-size: 16px;
    width: 100%;
  }
  /* .project-title {
    transform: translateY(-10px);
    opacity: 0;
    transition: all 0.5s ease-in-out;
  }
  .project-image-container:hover ~ .project-title {
    transform: translateY(0px);
    opacity: 1;
  }*/
  .desktop-subtitle {
    width: 50%;
  }
}
</style>
<script>
import { gsap} from "gsap";
  import Footer from '@/components/Footer.vue';
  export default {
  name: 'App',
  components: {
    Footer
  },
  mounted: function(){
    this.startAnimations()
    },
    methods: {
    startAnimations: function(){
     gsap.to(".blura",1, {opacity: 1,y:0, filter:'blur(0.1px)'})
  }
}
}
</script>
